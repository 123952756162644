<template>
    <div>
      <title class="mb-16">Page Not Found</title>
        <v-container fluid class="pt-16">
          <v-row>
            <v-img
                src="/images/icons/404.png"
                alt="Error 404 Image not found"
                height="250"
                class="my-md-15 my-5 pp-error"
                contain
            ></v-img>
          </v-row>

          <v-row class="text-center">
            <v-card-text class="text-md-h3 font-weight-bold pt-md-5 text-h6">
              <span class="primary--text pr-2">
                Error 404:
              </span>
              Sorry, page not found.
            </v-card-text>

            <v-card-text class="text-md-h6 text-subtitle">
              It might have been removed, renamed, or did not exist in the first place.
            </v-card-text>
          </v-row>

          <div class="text-center">
            <v-btn
                color="primary"
                class="rounded-xl pp-scale-1">
              <GoBackButton />
            </v-btn>

            <v-btn
                title="Homepage"
                aria-labelledby="Go to Homepage"
                class="px-10 ma-3 pp-scale-1"
                outlined
                color="secondary"
                rounded
                plain
                :to="{ name: 'Home' }"
            >
              <LearningPlusLogo :width="120" />
            </v-btn>

            <v-row class="my-5">
              <v-card-text class="text-subtitle">
                If you think something is broken,
                <a :href="supportEmail.href"
                   @click="matomoEvent('Contact Support', 'Contact Support Click', 'Contact Support Email');"
                   :title="supportEmail.title"
                   :aria-labelledby="supportEmail.linkText">
                  report this problem.
                </a>
              </v-card-text>
            </v-row>

            <v-divider />
          </div>
        </v-container>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";
import GoBackButton from "@/components/buttons/GoBackButton";
export default {
  components: {GoBackButton, LearningPlusLogo},
  head: {
    titleTemplate: 'Page Not Found',
  },
  computed: {
    ...mapGetters(['supportEmail']),
  },
};
</script>
